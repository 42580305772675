import Vue from 'vue';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import moment from 'moment';

const state: any = {
    today: '',
    present: false,
    myEntriesToday: {},
    min: '00:00'
};

const mutations = {
    loadStatus: (state: any, payload: any) => {
        Vue.set(state, 'present', false);
        Vue.set(state, 'min', '00:00');
        const currentUser = firebase.auth().currentUser;

        // Wurde für den Tag ein Eintrag gefunden?
        if (payload !== undefined) {
            // Gibt es einen Eintrag für den aktuellen Benutzer?
            const entries = payload[currentUser.email];
            if (entries !== undefined) {
                const keys = Object.keys(entries).sort();
                const myEntriesToday = [];
                for (const key of keys) {
                    myEntriesToday.push(entries[key]);
                }
                Vue.set(state, 'myEntriesToday', myEntriesToday);

                // Key des letzten Eintrags ermitteln
                const lastEntryKey = Object.keys(state.myEntriesToday)[Object.keys(state.myEntriesToday).length - 1];
                if (state.myEntriesToday[lastEntryKey].status === 'anwesend') {
                    Vue.set(state, 'present', true);
                    Vue.set(state, 'min', state.myEntriesToday[lastEntryKey].time);
                }
            }
        }
    }
};

const actions = {
    /**
     * Erstellt einen Eintrag in der Datenbank - Anwesend
     *
     * @param commit
     * @param present
     * @param time
     */
    update: ({ commit }: any, time: string) => {
        const currentUser = firebase.auth().currentUser;
        // const today = moment().format('YYYY-MM-DD');

        firebase
            .firestore()
            .collection('corona')
            .doc(state.today)
            .set({
                [currentUser.email]: {
                    [moment().format('x')]: {
                        status: state.present ? 'abwesend' : 'anwesend',
                        name: currentUser.displayName,
                        time: time
                    }
                }
            }, { merge: true })
            .then(function () {
                console.log('Document successfully created or updated!');
            })
            .catch(function (error: any) {
                console.error('Error creating document: ', error);
            });
    },
    loadStatus: ({ commit }: any) => {
        console.log('Load Status');
        Vue.set(state, 'today', moment().format('YYYY-MM-DD'));

        firebase
            .firestore()
            .collection('corona')
            .doc(state.today)
            .onSnapshot(doc => {
                commit('loadStatus', doc.data());
            });
    },
    checkDate: ({ commit, dispatch }: any) => {
        setTimeout(() => {
            console.log('Fake Date');
            Vue.set(state, 'today', moment().format('YYYY-MM-01'));
        }, 121000);

        setInterval(() => {
            console.log('CheckingDate Store');
            if (state.today !== moment().format('YYYY-MM-DD')) {
                console.log('Date Change detected');
                dispatch('loadStatus');
            }
        }, 60000);
    }
};

const getters = {
    present: (state: any) => {
        return state.present;
    },
    myEntriesToday: (state: any) => {
        return state.myEntriesToday;
    },
    min: (state: any) => {
        return state.min;
    }
};

const namespaced = true;

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced
};
