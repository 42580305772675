import * as firebase from 'firebase/app';
import 'firebase/firestore';

const state: any = {
    competition: []
};

const mutations = {
    competition: (state: any, payload: any) => {
        state.competition = payload;
    }
};

const actions = {
    loadCompetition: ({ commit }: any) => {
        firebase
            .firestore()
            .collection('competition')
            .orderBy('title')
            .onSnapshot(querySnapshot => {
                const competition: any = [];
                querySnapshot.forEach(doc => {
                    competition.push({
                        id: doc.id,
                        card: {
                            title: doc.data().title,
                            data: JSON.parse(doc.data().data),
                            type: doc.data().type,
                            date: doc.data().date
                        }
                    });
                });

                commit('competition', competition);
            });
    },

    createCompetition: ({ commit }: any, payload: any) => {
        let title = '';
        if (payload.title === 'landesliga') {
            title = 'Landesliga';
        }

        if (payload.title === 'club') {
            title = 'Club-/Standardklasse';
        }

        if (payload.title === 'dosi') {
            title = '18m/Leistungs-/Offeneklasse';
        }

        if (payload.title === 'junioren') {
            title = 'Junioren';
        }

        const competition = {
            title,
            data: payload.data,
            type: 'competition',
            date: new Date()
        };

        firebase
            .firestore()
            .collection('competition')
            .doc(payload.title)
            .set(competition)
            .catch(error => {
                console.log(error);
            });
    }
};

const getters = {
    competition: (state: any) => {
        return state.competition;
    }
};

const namespaced = true;

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced
};
