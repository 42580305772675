import * as firebase from 'firebase/app';
import 'firebase/firestore';

const state: any = {
    camo: []
};

const mutations = {
    camo: (state: any, payload: any) => {
        state.camo = payload;
    }
};

const actions = {
    loadData: ({ commit }: any) => {
        firebase
            .firestore()
            .collection('infodesk')
            .doc('camo')
            .onSnapshot(doc => {
                commit('camo', doc.data());
            });
    }
};

const getters = {
    camo: (state: any) => {
        return state.camo;
    }
};

const namespaced = true;

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced
};
