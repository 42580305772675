import router from '@/router';
import { Base64 } from '@/base64/base64.ts';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import Axios from 'axios/index';

const state: any = {
    apikey: null,
    user: null,
    roles: [],
    rolekeys: [],
    userkey: null
};

const mutations = {
    login: (state: any, payload: any) => {
        state.user = payload;
    },
    logout: (state: any) => {
        state.apikey = null;
        state.user = null;
        state.roles = [];
        state.rolekeys = [];
        state.userkey = null;
    },
    setApiAccess: (state: any, payload: any) => {
        state.apikey = payload.apikey;
        state.roles = payload.roles;
        state.rolekeys = payload.rolekeys;
        state.userkey = payload.userkey;

        Axios.defaults.headers.common.apikey = state.apikey;
        Axios.defaults.headers.common.rolekeys = state.rolekeys.join(',');
        Axios.defaults.headers.common.userkey = state.userkey;
    }
};

const actions = {
    login: ({ commit }: any, payload: any) => {
        firebase
            .auth()
            .signInWithCustomToken(payload.data.token)
            .then(user => {
                const currentUser = firebase.auth().currentUser;

                if (currentUser !== null) {
                    currentUser.updateEmail(payload.data.user.email);
                    currentUser.updateProfile({
                        displayName:
              payload.data.user.firstname + ' ' + payload.data.user.lastname,
                        photoURL: ''
                    });
                }
                commit('login', user);

                // Redirect
                router.replace(payload.redirectRoute);
            })
            .catch(error => {
                console.log(error);
            });
    },
    autoLogin: ({ commit }: any, payload: any) => {
        const currentUser = firebase.auth().currentUser;

        if (currentUser !== null) {
            currentUser.getIdToken().then(idToken => {
                commit('login', payload);
                commit(
                    'setApiAccess',
                    JSON.parse(Base64.b64DecodeUnicode(idToken.split('.')[1]))
                );
            });
        }
    },
    logout: ({ commit }: any) => {
        firebase.auth().signOut();
        commit('logout');
    }
};

const getters = {
    isLoggedIn: (state: any) => {
        return state.userkey !== null;
    },
    hasRole: (state: any, getters: any) => (role: any) => {
        // Sonderfall Mitglied
        if (role === 'Mitglied') {
            return getters.isLoggedIn;
        }
        // Sonderfall Gruppe FI
        if (role === 'app_fi_segel') {
            return getters.isSegelfluglehrer;
        }
        return (
            role === '' || role === undefined || state.roles.indexOf(role) !== -1
        );
    },
    userkey: (state: any) => {
        return state.userkey;
    },
    username: (state: any) => {
        return state.user !== null ? state.user.displayName : '';
    },
    isSegelfluglehrer: (state: any) => {
        const currentUser = firebase.auth().currentUser;

        if (currentUser === null) {
            return false;
        }

        const segelfluglehrer = [
            'dirk.blicker@gmx.de',
            'anja@auwermann.de',
            's.besting@freenet.de',
            'andreas.cronrath@gmail.com',
            'aodresler@mailbox.org',
            'borisgorski@web.de',
            'c.meisel@gmx.de',
            'olaf.schumacher@t-online.de',
            'volker.splettstoesser@gmx.net',
            'steffen.trapp@freenet.de',
            'm.hisge@erfurt.com',
            'nicolas.huhn@gmx.net',
            'bastian.koppen@gmx.de',
            'johanneskrakau@gmail.com',
            'marcusschlueter@web.de',
            'ichbintanjastolz@gmail.com',
            'xfel.wirth@gmail.com'
        ];

        return (segelfluglehrer.indexOf(currentUser.email.toLowerCase()) > -1);
    },
    isSegelfluglehrerAdmin: (state: any) => {
        const currentUser = firebase.auth().currentUser;

        if (currentUser === null) {
            return false;
        }

        const segelfluglehrer = [
            'dirk.blicker@gmx.de',
            'anja@auwermann.de'
        ];

        return (segelfluglehrer.indexOf(currentUser.email.toLowerCase()) > -1);
    }
};

const namespaced = true;

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced
};
