import * as firebase from 'firebase/app';
import 'firebase/auth';
import store from '../store';

export default (to: any, from: any, next: any) => {
    if (firebase.auth().currentUser !== null) {
        if (
            (to.path === '/planes' && !store.getters['user/hasRole']('Mitglied AKTIV')) ||
            (to.path === '/infodesk' && !store.getters['user/hasRole']('Mitglied AKTIV')) ||
            (to.path === '/gasstation' && !store.getters['user/hasRole']('app_tankstelle') && !store.getters['user/hasRole']('app_rechnungen')) ||
            (to.path === '/fluglehrer_motorflug' && !store.getters['user/hasRole']('app_fi_motor')) ||
            (to.path === '/fluglehrer_segelflug' && !store.getters['user/isSegelfluglehrer'])
        ) {
            next('/');
        } else {
            next();
        }
    } else {
        next('/login?redirect=' + to.path);
    }
};
