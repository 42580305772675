import * as firebase from 'firebase/app';
import 'firebase/firestore';

const state: any = {
    //  prices: {},
};

const mutations = {
    //  setPrices: (state: any, payload: any) => {
    //    state.prices = payload;
    //  },
};

const actions = {
    setUpdate: ({ commit }: any, payload: any) => {
        const currentUser = firebase.auth().currentUser;

        if (currentUser !== null) {
            firebase
                .firestore()
                .collection('tankstelle_db_updates')
                .add({
                    type: payload.type,
                    task: payload.task,
                    user: currentUser.displayName,
                    email: currentUser.email,
                    crdate: Date.now(),
                    updated: ''
                })
                .then(docRef => {
                    console.log('Document written with ID: ', docRef.id);
                })
                .catch(error => {
                    console.error('Error adding document: ', error);
                });
        }
    }
};

const getters = {};

const namespaced = true;

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced
};
