import * as firebase from 'firebase/app';
import 'firebase/firestore';

const state: any = {
    students: []
};

const mutations = {
    students: (state: any, payload: any) => {
        state.students = payload;
    }
};

const actions = {
    loadData: ({ commit }: any) => {
        firebase
            .firestore()
            .collection('fi_segel')
            .where('deleted', '==', false)
            .orderBy('name', 'asc')
            .onSnapshot((querySnapshot: any) => {
                const students: any = [];
                querySnapshot.forEach((doc: any) => {
                    students.push({ id: doc.id, data: doc.data() });
                });
                commit('students', students);
            });
    },

    createStudent: ({ commit }: any, payload: any) => {
        const date: Date = new Date();

        const student = {
            name: payload.name,
            milestone: '',
            created: date,
            createdBy: payload.user,
            changed: date,
            changedBy: payload.user,
            deleted: false,
            user: payload.user
        };

        firebase
            .firestore()
            .collection('fi_segel')
            .doc(String(date.getTime()))
            .set(student)
            .catch(function (error: any) {
                console.log(error);
            });
    },

    editStudent: ({ commit }: any, payload: any) => {
        firebase
            .firestore()
            .collection('fi_segel')
            .doc(payload.student.id)
            .update({
                name: payload.name,
                milestone: payload.milestone,
                changed: new Date(),
                changedBy: payload.user
            })
            .then(function () {
                console.log('Document successfully updated!');
            })
            .catch(function (error: any) {
                // The document probably doesn't exist.
                console.error('Error updating document: ', error);
            });
    },

    createEntry: ({ commit }: any, payload: any) => {
        const date: Date = new Date();

        const entry = {
            topic: payload.topic,
            content: payload.content,
            created: date,
            createdBy: payload.user,
            changed: date,
            changedBy: payload.user,
            comments: false
        };

        const key = 'entries.' + date.getTime();

        firebase
            .firestore()
            .collection('fi_segel')
            .doc(payload.student.id)
            .update({
                [key]: entry
            })
            .then(function () {
                console.log('Document successfully updated!');
            })
            .catch(function (error: any) {
                // The document probably doesn't exist.
                console.error('Error updating document: ', error);
            });
    },

    editEntry: ({ commit }: any, payload: any) => {
        const entry = payload.student.data.entries[payload.entryId];
        entry.topic = payload.topic;
        entry.content = payload.content;
        entry.changed = new Date();
        entry.changedBy = payload.user;

        const key = 'entries.' + payload.entryId;

        firebase
            .firestore()
            .collection('fi_segel')
            .doc(payload.student.id)
            .update({
                [key]: entry
            })
            .then(function () {
                console.log('Document successfully updated!');
            })
            .catch(function (error: any) {
                // The document probably doesn't exist.
                console.error('Error updating document: ', error);
            });
    },

    createComment: ({ commit }: any, payload: any) => {
        const date: Date = new Date();

        const comment = {
            content: payload.content,
            created: date,
            createdBy: payload.user,
            changed: date,
            changedBy: payload.user
        };

        const key = 'entries.' + payload.entryId + '.comments.' + date.getTime();

        firebase
            .firestore()
            .collection('fi_segel')
            .doc(payload.student.id)
            .update({
                [key]: comment
            })
            .then(function () {
                console.log('Document successfully updated!');
            })
            .catch(function (error: any) {
                // The document probably doesn't exist.
                console.error('Error updating document: ', error);
            });
    },

    // Setzt den Datensatz auf deleted / Wird aber nicht wirklich geöscht
    removeStudent: ({ commit }: any, payload: any) => {
        const student = {
            deleted: new Date(),
            deletedBy: payload.user
        };

        firebase
            .firestore()
            .collection('fi_segel')
            .doc(payload.student.id)
            .update(student)
            .then(function () {
                console.log('Document successfully updated!');
            })
            .catch(function (error: any) {
                // The document probably doesn't exist.
                console.error('Error updating document: ', error);
            });
    },

    removeEntry: ({ commit }: any, payload: any) => {
        const entry = payload.student.data.entries[payload.entryId];
        entry.deleted = new Date();
        entry.deletedBy = payload.user;

        const key = 'entries.' + payload.entryId;

        firebase
            .firestore()
            .collection('fi_segel')
            .doc(payload.student.id)
            .update({
                [key]: entry
            })
            .then(function () {
                console.log('Document successfully updated!');
            })
            .catch(function (error: any) {
                // The document probably doesn't exist.
                console.error('Error updating document: ', error);
            });
    },

    removeComment: ({ commit }: any, payload: any) => {
        const comment = payload.student.data.entries[payload.entryId].comments[payload.commentId];
        comment.deleted = new Date();
        comment.deletedBy = payload.user;

        const key = 'entries.' + payload.entryId + '.comments.' + payload.commentId;

        firebase
            .firestore()
            .collection('fi_segel')
            .doc(payload.student.id)
            .update({
                [key]: comment
            })
            .then(function () {
                console.log('Document successfully updated!');
            })
            .catch(function (error: any) {
                // The document probably doesn't exist.
                console.error('Error updating document: ', error);
            });
    },

    setDone: ({ commit }: any, payload: any) => {
        const entry = payload.student.data.entries[payload.entryId];
        entry.done = payload.flag;
        entry.doneChanged = new Date();
        entry.doneBy = payload.user;

        const key = 'entries.' + payload.entryId;

        firebase
            .firestore()
            .collection('fi_segel')
            .doc(payload.student.id)
            .update({
                [key]: entry
            })
            .then(function () {
                console.log('Document successfully updated!');
            })
            .catch(function (error: any) {
                // The document probably doesn't exist.
                console.error('Error updating document: ', error);
            });
    }
};

const getters = {
    students: (state: any) => {
        return state.students;
    }
};

const namespaced = true;

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced
};
