import Vue from 'vue';
import Vuex from 'vuex';
import Vuetify from 'vuetify';
import * as firebase from 'firebase/app';
// import * as firebase from 'firebase';
import 'firebase/firestore';
import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';
import de from 'vuetify/src/locale/de';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import 'vuetify/dist/vuetify.min.css';
import VueTheMask from 'vue-the-mask';

Vue.use(Vuex);
Vue.use(Vuetify, {
    lang: {
        locales: { de },
        current: 'de'
    }
});

Vue.use(VueTheMask);

Vue.config.productionTip = false;

let app: any;
const config = {
    apiKey: 'AIzaSyDDNUIE41m-nBWJOb5AcuDATATB-sIQ1vo',
    authDomain: 'lsc-quick-reference.firebaseapp.com',
    databaseURL: 'https://lsc-quick-reference.firebaseio.com',
    projectId: 'lsc-quick-reference',
    storageBucket: 'gs://lsc-quick-reference.appspot.com',
    messagingSenderId: '1032129083606',
    appId: '1:1032129083606:web:15425b5674285afdee3177'
};

firebase.initializeApp(config);
const firestore = firebase.firestore();
firestore.enablePersistence();

firebase.auth().onAuthStateChanged((user: any) => {
    if (!app) {
    /* eslint-disable no-new */
        new Vue({
            el: '#app',
            router,
            store,
            components: { App },
            template: '<App/>',
            created () {
                if (firebase.auth().currentUser !== null) {
                    this.$store.dispatch('user/autoLogin', user);
                } else {
                    this.$router.push('/emergency');
                }

                // Load Data in Background
                this.$store.dispatch('news/loadData');
                // this.$store.dispatch('competition/loadCompetition');
                this.$store.dispatch('bookings/loadData');
                this.$store.dispatch('camo/loadData');
                this.$store.dispatch('fluglehrerMotorflug/loadData');
                this.$store.dispatch('statistics/towing');
                this.$store.dispatch('statistics/flightSchool');
                this.$store.dispatch('statistics/planes');
                this.$store.dispatch('statistics/guestFlights');
                this.$store.dispatch('statistics/guestFlightsGlider');

                if (this.$store.getters['user/isSegelfluglehrer']) {
                    this.$store.dispatch('fluglehrerSegelflug/loadData');
                }
            }
        });
    }
});
