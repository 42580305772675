
import Component from 'vue-class-component';
import { QrcodeStream } from 'vue-qrcode-reader';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import moment from 'moment';
import { mask } from 'vue-the-mask';

const ENVIRONMENT = process.env.VUE_APP_ENVIRONMENT;

@Component({
    components: {
        QrcodeStream
    },
    computed: {
        ...mapGetters({
            code: 'vouchers/code',
            voucher: 'vouchers/voucher',
            info: 'vouchers/info',
            progress: 'vouchers/progress',
            pilots: 'vouchers/pilots',
            aircraft: 'vouchers/aircraft'
        })
    }
})
export default class VoucherPage extends Vue {
    public environment: string = ENVIRONMENT;
    public selectedPilot: string = '';
    public selectedAircraft: string = '';
    public showResetDialog: boolean = false;
    public hasCamera: boolean = false;
    public manualVoucherCode: string = '';

    directives = {
        mask
    };

    public mounted (): void {
        this.abort();
        this.checkCamera();
        this.$store.dispatch('vouchers/loadPilots');
        this.$store.dispatch('vouchers/loadAircraft');
    }

    public checkCamera = async () => {
        try {
            const devices = await navigator.mediaDevices.enumerateDevices();
            this.hasCamera = devices.some(device => device.kind === 'videoinput');
        } catch (error) {
            console.error('Fehler beim Prüfen der Kamera:', error);
            this.hasCamera = false;
        }
    }

    public onDecode (code: string): any {
        this.$store.dispatch('vouchers/checkCode', { code: code });
    }

    public onManualDecode (): any {
        this.$store.dispatch('vouchers/checkCode', { code: this.manualVoucherCode });
    }

    public abort (): void {
        this.selectedPilot = '';
        this.selectedAircraft = '';
        this.$store.dispatch('vouchers/abort');
    }

    public redeemVoucher (): any {
        this.$store.dispatch('vouchers/redeemVoucher', { pilot: this.selectedPilot, aircraft: this.selectedAircraft });
        this.selectedPilot = '';
        this.selectedAircraft = '';
    }

    public confirmResetVoucher (): void {
        this.showResetDialog = false;
        this.resetVoucher();
    }

    public resetVoucher (): any {
        this.$store.dispatch('vouchers/resetVoucher');
    }

    public formatDate = (date: string | Date) => {
        return moment(date).format('DD.MM.YYYY HH:mm');
    }

    public redeemedToday = (date: string | Date) => {
        return moment(new Date()).format('DD.MM.YYYY') === moment(date).format('DD.MM.YYYY');
    }

    public toUppercase () {
        this.manualVoucherCode = this.manualVoucherCode.toUpperCase();
    }

    // Computed Property: Ist der Code vollständig?
    get isCodeComplete (): boolean {
        return /^(MF|SF|SK)-[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}$/.test(this.manualVoucherCode);
    }
}
