import * as firebase from 'firebase/app';
import 'firebase/firestore';

const state: any = {
    towing: [],
    flightSchool: [],
    planes: [],
    guestFlights: [],
    guestFlightsGlider: []
};

const mutations = {
    towing: (state: any, payload: any) => {
        state.towing = payload;
    },
    flightSchool: (state: any, payload: any) => {
        state.flightSchool = payload;
    },
    planes: (state: any, payload: any) => {
        state.planes = payload;
    },
    guestFlights: (state: any, payload: any) => {
        state.guestFlights = payload;
    },
    guestFlightsGlider: (state: any, payload: any) => {
        state.guestFlightsGlider = payload;
    }
};

const actions = {
    towing: ({ commit }: any) => {
        firebase
            .firestore()
            .collection('statistics')
            .doc('towing')
            .onSnapshot(doc => {
                commit('towing', doc.data());
            });
    },
    flightSchool: ({ commit }: any) => {
        firebase
            .firestore()
            .collection('statistics')
            .doc('flightSchool')
            .onSnapshot(doc => {
                commit('flightSchool', doc.data());
            });
    },
    planes: ({ commit }: any) => {
        firebase
            .firestore()
            .collection('statistics')
            .doc('planes')
            .onSnapshot(doc => {
                commit('planes', doc.data());
            });
    },
    guestFlights: ({ commit }: any) => {
        firebase
            .firestore()
            .collection('statistics')
            .doc('guestFlights')
            .onSnapshot(doc => {
                commit('guestFlights', doc.data());
            });
    },
    guestFlightsGlider: ({ commit }: any) => {
        firebase
            .firestore()
            .collection('statistics')
            .doc('guestFlightsGlider')
            .onSnapshot(doc => {
                commit('guestFlightsGlider', doc.data());
            });
    }
};

const getters = {
    towing: (state: any) => {
        return state.towing;
    },
    towingMax: (state: any) => {
        return state.towing.data !== undefined && state.towing.data.length > 0
            ? state.towing.data[0].towings
            : 0;
    },
    flightSchool: (state: any) => {
        return state.flightSchool;
    },
    flightSchoolMax: (state: any) => {
        return state.flightSchool.data !== undefined &&
      state.flightSchool.data.length > 0
            ? state.flightSchool.data[0].starts
            : 0;
    },
    planes: (state: any) => {
        return state.planes;
    },
    planesMax: (state: any) => {
        return state.planes.data !== undefined && state.planes.data.length > 0
            ? state.planes.data[0].timeInt
            : 0;
    },
    guestFlights: (state: any) => {
        return state.guestFlights;
    },
    guestFlightsMax: (state: any) => {
        return state.guestFlights.data !== undefined &&
      state.guestFlights.data.length > 0
            ? state.guestFlights.data[0].timeInt
            : 0;
    },
    guestFlightsGlider: (state: any) => {
        return state.guestFlightsGlider;
    },
    guestFlightsGliderMax: (state: any) => {
        return state.guestFlightsGlider.data !== undefined &&
      state.guestFlightsGlider.data.length > 0
            ? state.guestFlightsGlider.data[0].timeInt
            : 0;
    }
};

const namespaced = true;

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced
};
