import Vue from 'vue';
import axios from 'axios';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const API_VOUCHER_BASE_URL = process.env.VUE_APP_API_VOUCHER_BASE_URL;

if (!API_VOUCHER_BASE_URL) {
    throw new Error('API_VOUCHER_BASE_URL ist nicht definiert in den Umgebungsvariablen');
}

const state: any = {
    code: '',
    voucher: {},
    info: {
        type: '',
        message: ''
    },
    progress: false,
    pilots: [],
    aircraft: []
};

const mutations = {
    voucher: (state: any, payload: any) => {
        Vue.set(state, 'code', payload.code);
        Vue.set(state, 'voucher', payload.data);
    },
    abort: (state: any) => {
        Vue.set(state, 'code', '');
        Vue.set(state, 'voucher', {});
        Vue.set(state, 'info', { type: '', message: '' });
        Vue.set(state, 'progress', false);
    }
};

const actions = {
    loadPilots: async ({ commit }: any, payload: any) => {
        try {
            // REST-Service URL angeben
            const response = await axios.get(`${API_VOUCHER_BASE_URL}/api/pilots`, {
                headers: {
                    Authorization: '6868686a6f672c655648737a5f4f7133466b5b735248596526465a263f'
                }
            });

            state.pilots = response.data.member
                .sort((a: { firstname: string; lastname: string }, b: { firstname: string; lastname: string }) => a.lastname.localeCompare(b.lastname) || a.firstname.localeCompare(b.firstname))
                .map((member: { firstname: string; lastname: string }) => `${member.lastname}, ${member.firstname}`);
        } catch (error) {
            console.error('Fehler beim Abrufen der Daten:', error);
        }
    },
    loadAircraft: async ({ commit }: any, payload: any) => {
        try {
            // REST-Service URL angeben
            const response = await axios.get(`${API_VOUCHER_BASE_URL}/api/aircrafts`, {
                headers: {
                    Authorization: '6868686a6f672c655648737a5f4f7133466b5b735248596526465a263f'
                }
            });

            state.aircraft = response.data.member
                .sort((a: { registration: string; type: string; listorder: number }, b: { registration: string; type: string; listorder: number }) => a.listorder - b.listorder)
                .map((member: { id: number; registration: string; type: string }) => ({
                    value: member.registration, // Wert, der intern gespeichert wird
                    text: `${member.registration} - ${member.type}`
                }));
        } catch (error) {
            console.error('Fehler beim Abrufen der Daten:', error);
        }
    },

    /**
     * Prüft ob es einen Code in der Datenbank gibt
     * @param commit
     * @param payload
     */
    checkCode: async ({ commit }: any, payload: any) => {
        state.progress = true;

        try {
            const response = await axios.get(`${API_VOUCHER_BASE_URL}/api/vouchers/${payload.code}`, {
                headers: {
                    Authorization: '6868686a6f672c655648737a5f4f7133466b5b735248596526465a263f'
                }
            });

            state.code = payload.code;
            state.progress = false;
            state.info.type = '';
            state.info.message = '';
            state.voucher = response.data;
        } catch (error) {
            state.progress = false;
            state.info.type = 'error';

            state.info.message = error.message;
            if (error.message === 'Request failed with status code 404') {
                state.info.message = 'Gutscheincode konnte nicht gefunden werden!';
            }
            console.error('Fehler beim Abrufen der Daten:', error);
        }
    },

    /**
     * Löst einen Code ein
     * @param commit
     * @param payload
     */
    redeemVoucher: async ({ commit, dispatch }: any, payload: any) => {
        state.progress = true;

        try {
            const response = await axios.patch(`${API_VOUCHER_BASE_URL}/api/vouchers/${state.code}`,
                {
                    redeemed: true,
                    redeemed_by: firebase.auth().currentUser.displayName,
                    redeemed_pilot: payload.pilot,
                    redeemed_aircraft: payload.aircraft
                }, {
                    headers: {
                        Authorization: '6868686a6f672c655648737a5f4f7133466b5b735248596526465a263f',
                        'Content-Type': 'application/merge-patch+json'
                    }
                });

            if (response) {
                state.progress = false;
                await dispatch('checkCode', { code: state.code });
            }
        } catch (error) {
            state.progress = false;
            state.info.type = 'error';
            state.info.message = error.message; // Fehler behandeln
            console.error('Fehler beim Abrufen der Daten:', error);
        }
    },

    resetVoucher: async ({ commit, dispatch }: any, payload: any) => {
        try {
            const response = await axios.patch(`${API_VOUCHER_BASE_URL}/api/vouchers/${state.code}`,
                {
                    redeemed: false,
                    redeemed_by: '',
                    redeemed_pilot: '',
                    redeemed_aircraft: ''
                }, {
                    headers: {
                        Authorization: '6868686a6f672c655648737a5f4f7133466b5b735248596526465a263f',
                        'Content-Type': 'application/merge-patch+json'
                    }
                });

            if (response) {
                state.progress = false;
                await dispatch('checkCode', { code: state.code });
            }
        } catch (error) {
            state.progress = false;
            state.info.type = 'error';
            state.info.message = error.message; // Fehler behandeln
            console.error('Fehler beim Abrufen der Daten:', error);
        }
    },

    /**
     * Bricht einen Vorgang ab und setzt die Werte zurück
     * @param commit
     * @param payload
     */
    abort: ({ commit }: any, payload: any) => {
        state.code = '';
        state.voucher = {};
        state.info = {
            type: '',
            message: ''
        };
        state.progress = false;
    }
};

const getters = {
    code: (state: any) => {
        return state.code;
    },
    voucher: (state: any) => {
        return state.voucher;
    },
    info: (state: any) => {
        return state.info;
    },
    progress: (state: any) => {
        return state.progress;
    },
    pilots: (state: any) => {
        return state.pilots;
    },
    aircraft: (state: any) => {
        return state.aircraft;
    }
};

const namespaced = true;

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced
};
