const emergency = {
    cards: [
        {
            title: 'Rettungskette',
            type: 'html',
            content:
        '<ul>' +
        '<li>Helfer alarmieren und weitere Feuerlöscher mitbringen lassen!</li>' +
        '<li>Rettungsleitstelle benachrichtigen <a href="tel:+49226165028" style="color: #ffffff; text-decoration: none;"><button type="button" class="btn btn--block error" style="position: relative;"><div class="btn__content" style="font-size: 1.8rem;">02261 65028</div></button></a>' +
        '<div style="color: #30475d;; font-size: 1.2em; font-weight: bold; background-color: #ffffff; padding: 5px 0 5px 10px; border-radius: 3px;">' +
        'Wer meldet das Ereignis ?<br />' +
        'Wo geschah es ?<br />' +
        'Was ist geschehen ?<br />' +
        'Wie viele Verletzte ?<br />' +
        'Warten auf Rückfragen !<br />' +
        'ERSTE HILFE LEISTEN !<br />' +
        'RETTUNGSKRÄFTE EINWEISEN !</div>' +
        '</li>' +
        '<div style="text-decoration: underline;">Wenn Flugleiter/Betriebsleiter vor Ort:</div>' +
        '<li>Rettungsfahrzeug besetzen (lassen) und zur Unfallstelle fahren<br />(mit möglichst 2 Helfern)</li>' +
        '<li>Meldung Flugfunk:<div style="margin-top: 10px; color: #30475d;; font-size: 1.2em; font-weight: bold; background-color: #ffffff; padding: 5px 0 5px 10px; border-radius: 3px;">Bergneustadt RADIO an alle:<br />Piste gesperrt, derzeit keine Landungen möglich</div></li>' +
        '</ul>'
        },
        {
            title: 'Telefonnummern',
            type: 'table',
            classes: 'padding bgDarkBlue',
            data: [
                {
                    key: 'Rettungsdienst',
                    description: '',
                    value:
            '<a href="tel:112" style="color: #ffffff; text-decoration: none;"><button type="button" class="btn btn--small theme--dark error" style="margin: 0; width: 100px; position: relative;"><div class="btn__content" style="font-size: 1.2rem;">112</div></button></a>'
                },
                {
                    key: 'Polizei',
                    description: '',
                    value:
            '<a href="tel:110" style="color: #ffffff; text-decoration: none;"><button type="button" class="btn btn--small theme--dark error" style="margin: 0; width: 100px; position: relative;"><div class="btn__content" style="font-size: 1.2rem;">110</div></button></a>'
                },
                {
                    key: 'Rettungsleitstelle Oberberg',
                    description: '',
                    value:
            '<a href="tel:+49226165028" style="color: #ffffff; text-decoration: none;"><button type="button" class="btn btn--small theme--dark primary" style="margin: 0; width: 100px; position: relative;"><div class="btn__content">02261 65028</div></button></a>'
                },
                {
                    key: 'Rettungsleitstelle Olpe',
                    description: '',
                    value:
            '<a href="tel:+49276196600" style="color: #ffffff; text-decoration: none;"><button type="button" class="btn btn--small theme--dark primary" style="margin: 0; width: 100px; position: relative;"><div class="btn__content">02761 96600</div></button></a>'
                },
                {
                    key: 'Rettungsleitstelle MK',
                    description: '',
                    value:
            '<a href="tel:+49235110650" style="color: #ffffff; text-decoration: none;"><button type="button" class="btn btn--small theme--dark primary" style="margin: 0; width: 100px; position: relative;"><div class="btn__content">02351 10650</div></button></a>'
                },
                {
                    key: 'BFU',
                    description: '',
                    value:
            '<a href="tel:+4953135480" style="color: #ffffff; text-decoration: none;"><button type="button" class="btn btn--small theme--dark primary" style="margin: 0; width: 100px; position: relative;"><div class="btn__content">0531 35480</div></button></a>'
                },
                {
                    key: 'SAR Münster',
                    description: '',
                    value:
            '<a href="tel:+49251135757" style="color: #ffffff; text-decoration: none;"><button type="button" class="btn btn--small theme--dark primary" style="margin: 0; width: 100px; position: relative;"><div class="btn__content">0251 135757</div></button></a>'
                },
                {
                    key: 'DFS Bezirkskontrollstelle',
                    description: '',
                    value:
            '<a href="tel:+4961037076600" style="color: #ffffff; text-decoration: none;"><button type="button" class="btn btn--small theme--dark primary" style="margin: 0; width: 100px; position: relative;"><div class="btn__content">06103 7076600</div></button></a>'
                },
                {
                    key: 'Bezirksregierung Düsseldorf',
                    description: '',
                    value:
                        '<a href="tel:+49211475680" style="color: #ffffff; text-decoration: none;"><button type="button" class="btn btn--small theme--dark primary" style="margin: 0; width: 100px; position: relative;"><div class="btn__content">0211 475680</div></button></a>'
                },
                {
                    key: 'Dümpel Clubhaus',
                    description: '',
                    value:
            '<a href="tel:+4927637596" style="color: #ffffff; text-decoration: none;"><button type="button" class="btn btn--small theme--dark primary" style="margin: 0; width: 100px; position: relative;"><div class="btn__content">02763 7596</div></button></a>'
                },
                {
                    key: 'Dümpel Turm',
                    description: '',
                    value:
            '<a href="tel:+492763387" style="color: #ffffff; text-decoration: none;"><button type="button" class="btn btn--small theme--dark primary" style="margin: 0; width: 100px; position: relative;"><div class="btn__content">02763 387</div></button></a>'
                }
            ]
        },
        {
            title: 'Frequenzen',
            type: 'table',
            classes: 'bgBlue',
            data: [
                { key: 'Notfall', description: '', value: '121.500' },
                { key: 'Rescue Münster', description: '', value: '123.100' }
            ]
        }
    ]
};

export default emergency;
